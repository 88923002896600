 // Import the functions you need from the SDKs you need
 import { initializeApp } from "firebase/app";
 import { getFirestore } from "firebase/firestore";
  

const firebaseConfig = {
    apiKey: "AIzaSyB3TySOmcRn-ZSHLzy2YZqjSvKiJNA4vLE",
    authDomain: "rosieai-7bc72.firebaseapp.com",
    projectId: "rosieai-7bc72",
    storageBucket: "rosieai-7bc72.appspot.com",
    messagingSenderId: "290526686955",
    appId: "1:290526686955:web:b6181b219e00b9f1ed58ae",
    measurementId: "G-1WQ8NH66LR"
  };

const app = initializeApp(firebaseConfig);
 
export const db = getFirestore(app);
