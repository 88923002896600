import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo/logo.png";
import { Footer_link1, Footer_link2, Footer_link3, Copyright_link } from "./utils/String";
import { Facebook, Instagram, Twitter, Github, Dribble } from "./utils/Config";
function Footer() {
	return (
		<>
			<footer className="footer-section ">
				<div className="container">  
                    <div className="footer-bottom">
                    <div className="copyright">
                                Copyright &copy; 2024. All Rights Reserved by <Link to="/">{Copyright_link}</Link>
                            </div>
                    </div>
				</div>
			</footer>
		</>
	);
}

export default Footer;