export const Header_link1 = "Home";
export const Header_link2 = "About";
export const Header_link3 = "Pricing";
export const Header_btn = "Join Waitlist";


export const Banner_title = "Meet Rosie, your AI Life Manager.";





export const Banner_para = "Get more things DONE, in one place. Rosie helps you DO the things you need to do, faster, Saving time, for you to BUILD memories.!";
export const Banner_btn = "Join Waitlist";


export const ImageOverview_subtitle = "Image Generate";
export const ImageOverview_title = "Easy way to generate images using ChatGPT through Rosie Ai at low cost";
export const ImageOverview_para1 = "Rosie Ai Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sit ullam sequi, dignissimos eligendi reprehenderit rerum eius dolores fugit, assumenda ipsam maxime labore! Voluptatibus ratione odit perspiciatis corrupti repellendus tempore, fugiat, eum provident, tempora iste soluta. Ad nihil quos quas aspernatur placeat amet voluptatem delectus dolore, vel dicta placeat amet voluptatem delectus dolore, vel dicta voluptas explicabo deleniti maxime suscipit praesentium veritatis hic ex accusantium tempore facilis.";
export const ImageOverview_para2 = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, esse ab tempora ipsa nobis illum sunt molestias ratione cum modi tenetur fugiat ullam, repellat corrupti fugit.";


export const TextOverview_subtitle = "Text Generate";
export const TextOverview_title = "Write a composition using ChatGPT through Rosie Ai at a low cost";
export const TextOverview_para1 = "Rosie Ai Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sit ullam sequi, dignissimos eligendi reprehenderit rerum eius dolores fugit, assumenda ipsam maxime labore! Voluptatibus ratione odit perspiciatis corrupti repellendus tempore, fugiat, eum provident, tempora iste soluta. Ad nihil quos quas aspernatur placeat amet voluptatem placeat amet voluptatem delectus dolore, vel dicta delectus dolore, vel dicta voluptas explicabo deleniti maxime suscipit praesentium veritatis hic ex accusantium tempore facilis.";
export const TextOverview_para2 = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, esse ab tempora ipsa nobis illum sunt molestias ratione cum modi tenetur fugiat ullam, repellat corrupti fugit.";


export const About_subtitle = "Know About Us";
export const About_title = "We Tackle Business Issues With Modern Multiple Innovation";
export const About_para = "Rosie Ai Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sit ullam sequi, dignissimos eligendi reprehenderit rerum eius dolores fugit, assumenda ipsam maxime labore! Voluptatibus ratione odit perspiciatis corrupti repellendus tempore, fugiat, eum provident, tempora iste soluta. Ad nihil quos quas aspernatur placeat amet voluptatem placeat amet voluptatem delectus dolore, vel dicta delectus dolore, vel dicta voluptas explicabo deleniti maxime suscipit praesentium veritatis hic ex accusantium tempore facilis.";


export const PlanLeft_subtitle = "Pricing Plan";
export const PlanLeft_title = "Connect with Rosie Ai to use chatgpt at a minimal cost";
export const PlanLeft_para = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, esse ab tempora ipsa nobis illum sunt molestias ratione cum modi tenetur fugiat ullam.";
export const PlanFree_subtitle = "Free";
export const PlanPremium_subtitle = "Premium";
export const Plan_btn = "Get Now";


export const Footer_link1 = "Privacy Policy";
export const Footer_link2 = "Terms & Condition";
export const Footer_link3 = "Refund Policy";
export const Copyright_link = "BandraRoad AI";


export const Logo_text = "heyRosie";