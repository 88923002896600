import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import bgimg from "../assets/images/element/new.jpg";
import robot1 from "../assets/images/element/robot-1.png"
import robot2 from "../assets/images/element/robot-2.png"
import robot3 from "../assets/images/element/robot-3.png"
import rose from "../assets/images/element/rosie-shadow.png"
import homevideo from "../assets/homevideo.mp4"

import '../assets/css/Banner.css'; 

function Banner() {
    const [showModal, setShowModal] = useState(false);

    // Function to handle showing the modal
    const handleShowModal = () => {
        setShowModal(true); // Update state to show the modal
    };

    return (
        <section className="banner-section">
            <div className="container-fluid" style={{ zIndex: 50, marginLeft: '-5px' }}>
                {/* First Row */}
                <div className="row h-200 align-items-center justify-content-between" style={{ overflow: 'visible' }}>
                    <div className="col-lg-5 col-md-5 col-sm-12 banner-left">
                        <div className="text-section" style={{ marginLeft: '-5px' }}>
                            <h1>Your <span className="highlight">Digital Life Manager.</span></h1>
                            <h2>Get more things done. <span className="highlight">All in one place.</span></h2>
                            <p style={{ marginBottom: '15px', fontSize: '0.9rem', marginLeft: '5px' }}>
                                Rosie is your AI personal assistant that goes beyond reminders to help you DO the things you need to do online - saving you time to build more memories.
                            </p>
                            <a
                                href="#downloadModal"
                                className="btn btn-dark join-waitlist-btn"
                                data-bs-toggle="modal"
                                onClick={handleShowModal}
                                style={{ marginBottom: '50px', textDecoration: 'none', color: "#fff" , zIndex: "20"}}
                            >
                                JOIN WAITLIST
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 banner-right ml-auto position-relative"
                        style={{
                            height: 'calc(70vh - 1px)', // Adjusted height with spacing at the top
                        }}
                    >
                        <video className="banner-video" autoPlay loop muted style={{ overflow: 'visible' }}>
                            <source src={homevideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

                {/* Second Row */}
                <div className="row align-items-center justify-content-center"> {/* Added margin top and removed on medium devices */}
                    <div className="col-lg-6 col-md-6 col-sm-1 banner-right"
                        style={{
                            background: `url(${robot3}) no-repeat center / 90%`, // Adjusted size with percentage
                            height: 'calc(125vh - 100px)',
                            marginTop: '-310px', // Adjusted negative margin
                            marginLeft: '-80px',
                            marginBottom: '-200px',
                            zIndex: 10,
                        }}
                    >
                    </div>

                    <div className="col-lg-5 col-md-5 col-sm-12 second-banner-left">
                        <div className="text-section">
                            <h1 style={{ marginBottom: '1px', marginTop: '10px' }}>
                                It's <span className="highlight">all about time</span>
                            </h1>
                            <h2 style={{ marginBottom: '0.1px', fontSize: '15px', lineHeight: '1.5' }}>
                                - Digital life should be easier and more connected.
                            </h2>
                            <h2 style={{ marginBottom: '1px', fontSize: '15px', lineHeight: '1.5' }}>
                                - Spend more time doing what you love.
                            </h2>
                            <h2 style={{ marginBottom: '1px', fontSize: '1.0rem', lineHeight: '1.5' }}>
                                - Rosie is built by parents for you.
                            </h2>
                        </div>
                    </div>


                </div>

                {/* Third Row */}
                <div className="row align-items-center justify-content-center mt-5"> {/* Added margin top to avoid overlapping */}
                    {/* Left Column - for PC view */}
                    <div className="col-lg-5 col-md-6 col-sm-12 d-none d-md-block banner-left">
                        <div className="text-section">
                            <img src={robot1} alt="robot" style={{ maxWidth: '35%', height: 'auto' }} />
                            <h2 style={{ marginBottom: '1px' }}>INTRODUCING</h2>
                            <h1>The Digital Soul</h1>
                            <p style={{ marginBottom: '1px', fontSize: '1.0rem', lineHeight: '1.5' }}>
                                Digital life should be easier and more connected. Rosie reimagines the way you use apps, with AI.
                                Spend more time doing what you love! 
                                Using our memory as a service platform

                            </p>
                            
                        </div>
                    </div>

                    {/* Left Column - for Mobile view */}
                        <div className="col-12 d-md-none text-center mb-4">
                            <img 
                                src={robot1} 
                                alt="robot" 
                                style={{ maxWidth: '35%', height: 'auto', display: 'block', margin: '0 auto' }} 
                            />
                            <div className="text-section">
                                <h2 style={{ marginBottom: '1px', fontSize: '1.2rem', textAlign: 'center' }}>INTRODUCING</h2>
                                <h1 style={{ textAlign: 'center' }}>The Digital Soul</h1>
                                <p style={{ marginBottom: '1px', fontSize: '1.0rem', lineHeight: '1.5', textAlign: 'center' }}>
                                    Digital life should be easier and more connected. Rosie reimagines the way you use apps, with AI.
                                    Spend more time doing what you love!
                                </p>
                            </div>
                        </div>


                    {/* Right Column - PC view */}
                    <div className="col-lg-6 col-md-6 col-sm-12 banner-right d-none d-md-flex justify-content-center align-items-center"
                        style={{
                            background: `url(${robot2}) no-repeat center / contain, url(${rose}) no-repeat center / contain`,
                            backgroundPosition: 'right , right', // Adjusted positions for both images
                            backgroundSize: 'contain', // Adjusted size for smaller images
                            height: '100vh', // Adjusted height to maintain aspect ratio
                            marginRight: '-150px', // Move the column 20px to the right
                        }}
                    >
                    </div>


                    {/* Right Column - Mobile view */}
                    <div className="col-12 d-flex d-md-none justify-content-center align-items-center"
                        style={{
                            background: `url(${robot2}) no-repeat center / contain, url(${rose}) no-repeat center / contain`,
                            backgroundPosition: 'right , right ', // Adjusted positions for both images
                            backgroundSize: '400px', // Adjusted size for smaller images
                            height: '100vh', // Adjusted height to maintain aspect ratio
                            marginRight: '3px', // Move the column 20px to the right
                        }}
                    >
                    </div>
                </div>

                {/* Fourth Row */}
                <div className="row align-items-center justify-content-center mt-5 d-none d-md-flex"> {/* Added responsive classes */}
                    <div className="col">
                        <div className="text-section text-center">
                            <h1>Looking to join in early?</h1>
                            <a
                                href="#downloadModal"
                                className="btn btn-dark custom-large-btn join-waitlist-btn"
                                data-bs-toggle="modal"
                                onClick={handleShowModal}
                                style={{ marginBottom: '100px', textDecoration: 'none', color: "#fff" }}
                            >
                                JOIN WAITLIST
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;
