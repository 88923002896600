import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import Logo from "../assets/images/logo/RosieLogo.png";
import { Header_btn } from "./utils/String";
import { db } from "../firebase";
import { collection, addDoc, getDocs } from "firebase/firestore";
import Snackbar from '@mui/material/Snackbar'; // Import Snackbar from MUI
import { Facebook, Instagram, LinkedIn } from "./utils/Config"; // Import icons

function Header() {
  const [modal] = useState(1);
  const [showSignupConfirmation, setShowSignupConfirmation] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const email = event.target.email.value.trim();

    if (!email) {
      setShowSignupConfirmation(true);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setShowSignupConfirmation(true);
      return;
    }

    try {
      const querySnapshot = await getDocs(collection(db, "waitlist"));
      const existingEmail = querySnapshot.docs.some(doc => doc.data().email === email);

      if (existingEmail) {
        setShowSignupConfirmation(true);
        return;
      }

      const docRef = await addDoc(collection(db, "waitlist"), {
        email: email
      });

      event.target.email.value = "";
      console.log("Document written with ID: ", docRef.id);
      setShowSignupConfirmation(true);
    } catch (error) {
      console.error("Error submitting email:", error);
    }
  };

  return (
    <>
      <header className="header-section">
        <div className="header">
          <div className="header-bottom-area">
            <div className="container-fluid">
              <div className="header-menu-content">
                <nav className="navbar navbar-expand-lg p-0">
                  <Link className="site-logo" to="/">
                    <img src={Logo} alt="site-logo" />
                  </Link>
                  <div className="social-icons ms-auto d-flex">
                    <a
                      href="https://www.facebook.com/profile.php?id=61559648766556&mibextid=ZbWKwL"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-icon"
                    >
                      <Facebook />
                    </a>
                    <a
                      href="https://www.instagram.com/heyrosie.ai"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-icon"
                    >
                      <Instagram />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/rosie-ai/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-icon"
                    >
                      <LinkedIn />
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      
      <div className="modal fade" id="downloadModal">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          {modal === 1 && (
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Join Waitlist</h4>
              </div>

              <div
                className="modal-body"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="download-wrapper">
                  <form
                    onSubmit={handleSubmit}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="form-group" style={{ marginRight: "20px" }}>
                      <label htmlFor="email" style={{ marginRight: "5px" }}>
                        Email:
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                      />
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Request Access
                    </button>
                  </form>
                  {showSignupConfirmation && (
                    <div className="signup-confirmation">
                      You are added to the waitlist!
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="floating-bar">
        <button className="btn--base" data-bs-toggle="modal" data-bs-target="#downloadModal">
          Join Waitlist
        </button>
      </div>

      <style jsx>{`
        .header-section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 20px;
        }

        .social-icons {
          display: flex;
          gap: 10px;
        }

        .floating-bar {
          position: fixed;
          bottom: 20px;
          right: 20px;
          background: #ff6b6b;
          color: #fff;
          padding: 10px 20px;
          border-radius: 5px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }

        .btn--base {
          background-color: #ff6b6b;
          color: #fff;
          border: none;
          padding: 10px 20px;
          border-radius: 5px;
          cursor: pointer;
        }

        .modal-content {
          text-align: left;
        }

        .signup-confirmation {
          margin-top: 20px;
          color: green;
        }
      `}</style>
    </>
  );
}

export default Header;
